import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog35/image1.jpg"
import image2 from "../../images/blog36/image1.jpg"
import image3 from "../../images/blog36/image2.jpg"
import image4 from "../../images/blog36/image3.jpg"
import image5 from "../../images/blog36/image4.png"
import image6 from "../../images/blog36/image5.png"
import image7 from "../../images/blog36/image6.png"
import image8 from "../../images/blog36/image7.jpg"
import image9 from "../../images/blog36/image8.png"
import image10 from "../../images/blog36/image9.jpg"
import image11 from "../../images/blog36/image10.jpg"









export default function HowToIncreaseClubHeadSpeed(){
    return(
        <BlogPostOld
            src={image}
            title={"Kettlebell Basics: How to Get Started"}
            summary={"When starting a Kettlebell program anticipate a learning curve. Think of using a kettlebell like learning martial arts, each progression like moving up in a belt. Using this analogy, a deadlift can be considered a white belt, a blue belt the two handed swing, and a black belt the double bell snatch. \n"}
            date={"Jun. 12, 2023"}
            author={"Dr. Ben DeLuca, DC"}

        >
            <div>
                <p><strong>Where to Get Started with Kettlebells</strong></p>
                <p>When starting a Kettlebell program anticipate a learning curve. Think of using a kettlebell like learning martial arts, each progression like moving up in a belt. Using this analogy, a deadlift can be considered a white belt, a blue belt the two handed swing, and a black belt the double bell snatch.&nbsp;</p>
                <p>You can still get tremendous results in performance without increasing or leveling up your kettlebell skills to the next belt by mastering the basics. As the legend Bruce Lee once said, “I fear not the man who has practiced 10,000 kicks once, but I fear the man who has practiced one kick 10,000 times”.</p>
                <p>The challenge kettlebells provide, the feeling of accomplishment when learning new exercises, coupled with the benefits of increases in speed and power are very rewarding for all who stick with the program. Whether you're younger, older, beginner, athlete, or fitness enthusiast the kettlebell provides an enriching opportunity to expand your fitness arsenal and skill set.&nbsp;</p>
                <p>Below are a couple exercises we use to both help get people over the fear of load as well as introduce basic strength training strategies to get stronger and prepare them for more athletic&nbsp; explosive movements like the swing. The KB deadlift and the suitcase march or single leg suitcase balance are a couple of our favorites, and can be easily adapted to existing programs.&nbsp;</p>
                <p>Try them out, let us know what you think.&nbsp;</p>
                <p><strong>The Kettlebell DeadLift</strong></p>
                <p>The KB deadlift is a safe and effective starting point to introduce the deadlift or hip hinge pattern. It can be the 1st step in gaining confidence to pick up weight off the ground and lead to future progressions into heavier barbell or trap bar methods.&nbsp;</p>
                <p>Its shape and handle allows for easy grip and floor placement directly under a person's center of mass. In order to get the bell under your center of mass you want to line the handle (AKA the horns) of the bell inline with your ankles as a start point.</p>
                <p>The key to a successful deadlift is having tension in the legs feeling long arms as you begin to push your feet into the ground. You should feel like a spring coiled up ready to explode up.One of the most common faults we see is lack of tension in the legs and forward head position (loss of neutral neck).&nbsp;</p>
                <p>Sometimes one of or the combination of these two things may lead to a shift in the target tissue from the back of the legs to the low back. If you feel the low back working at first it's ok. Sensation does not always equal harm especially when we are learning new things. This is also part of the learning process as we look for improvement not perfection. Take time in the set up and you should feel the back of the legs and hip area working as you find the start position. It may be uncomfortable as the legs are fighting to maintain tension, this is usually a good sign. The set up is the foundation for the lift.&nbsp; My coaches words echo in my brain before each time I lift&nbsp; “Hard start for an easy finish”</p>
                <p>Instructional video: <a href="https://youtu.be/P0w97WwRwVw" target="_blank" rel="noopener">https://youtu.be/P0w97WwRwVw</a></p>
                <p>Common Faults:&nbsp;</p>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={image3}/>Too squatty&nbsp;</td>
                            <td><img src={image11}/>Extended Neck&nbsp;</td>
                            <td><img src={image10}/>Rounded back “ Jumbo Shrimp”</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p>Correct start position and end position:&nbsp;</p>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={image4}/>Start&nbsp;</td>
                            <td><img src={image8}/>End&nbsp;</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p><strong>The Kettlebell Suitcase March</strong></p>
                <p>Kb suitcase march and single leg balance exercises have several benefits. It improves stability and promotes foot and ankle strength by rooting the foot into the ground.&nbsp;</p>
                <p>We recommend you try this bare foot so you can feel the floor by pushing the foot hard into the ground while locking your leg out. You should feel the abs opposite to the side of the weight you're holding, working to maintain a neutral hip position.&nbsp;</p>
                <p>Additional benefits include grip strength and core strength as you have to resist the force of the KB from pulling you sideways. This also can be a great introduction to load, providing useful information about a person's ability to balance.</p>
                <p>An athlete may have the ability to do hard things well like jump over a hurdle but may lack the ability to do simple things like balance on 1 leg. An increase in skill and capacity to perform a single leg balance may help an athlete become less likely to sprain an ankle and an older person with fall prevention.&nbsp;</p>
                <p>Instructional video:&nbsp; <a href="https://youtu.be/SUTuluoYato" target="_blank" rel="noopener">https://youtu.be/SUTuluoYato</a></p>
                <p>Common Faults:&nbsp;</p>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={image6}/>Hips shifting and shoulder sagged resulting in torso lean</td>
                            <td><img src={image7}/>Knee bent without pushing into ground</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <p>Correct Form</p>
                <div>
                    <table>
                        <tbody>
                        <tr>
                            <td><img src={image9}/>Hips and shoulders neutral resulting in upright torso</td>
                            <td><img src={image5}/>Knee straight as a result of pushing into ground with neck in tall neutral position</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"flex flex-row justify-center flex-wrap"}>
                    <a className={"lg:px-4 !text-white"} href={"tel:6196950688"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Call or Text Us and get started!</button></a>
                    <a className={"lg:px-4 !text-white"} href={"/contact"}><button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>Message us to learn more! </button></a>
                </div>
                <p><strong>If&nbsp; flare ups occur do not abort the plan. Managing the symptoms and adjusting the strategy to stay on course for the long term goals is vital. This is true whether you use a kettlebell, barbell, dumbbell, run, walk, play golf, play basketball… anything and everything in between.</strong></p>
            </div>

        </BlogPostOld>

    )
}